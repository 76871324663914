import { Title } from '@solidjs/meta';
import {
	BreadcrumbItem,
	Button,
	Container,
	Details,
	Heading,
	HorizontalRule,
	Label,
	Link,
	Page,
	Radio,
	RadioGroup,
	Section,
	Summary,
	Tab,
	TabList,
	TabPanel,
	Tabs,
	TextLink,
} from '@troon/ui';
import { createAsync } from '@solidjs/router';
import { createSignal, For, Match, Show, Switch } from 'solid-js';
import dayjs from '@troon/dayjs';
import { Hero } from '../../components/hero/photo';
import { getConfigValue } from '../../modules/config';
import { Grid, GridFive, GridSeven } from '../../components/layouts/grid';
import { gql } from '../../graphql';
import { cachedQuery } from '../../graphql/cached-get';
import type { RouteDefinition } from '@solidjs/router';

export default function RewardsRedemptionOptions() {
	const data = createAsync(() => getData({}), { deferStream: true });
	const [pointsType, setPointsType] = createSignal('free');
	return (
		<>
			<Title>Troon Rewards Redemption Options | Troon</Title>
			<Hero
				src={`${getConfigValue('IMAGE_HOST')}/digital/hero/rewards.png`}
				breadcrumbs={() => (
					<>
						<BreadcrumbItem href="/rewards">Troon Rewards</BreadcrumbItem>
						<BreadcrumbItem href="/rewards/redemption">Redemption Options</BreadcrumbItem>
					</>
				)}
			>
				<Heading as="h1">Redemption Options</Heading>
			</Hero>

			<Container>
				<Page>
					<Grid>
						<GridSeven>
							<Section>
								<Heading as="h2">Redeeming rounds of golf with Troon Rewards</Heading>
								<p>
									Members can use their Troon Rewards points towards one (1) complimentary round of golf based on the
									redemption table/schedule. Rounds of golf can also be redeemed by using a combination of Troon Rewards
									points and cash (based on the redemption chart).
								</p>

								<p>
									Example: A complimentary round of golf at a “Tier 4” course would require 800 Troon Rewards points or
									a combination of 400 Troon Rewards points and $40*.
								</p>
								<p>*VAT will be applied to points and points/cash redemptions in the United Arab Emirates.</p>

								<Button as={Link} href="#options" class="size-fit shrink">
									View courses
								</Button>
							</Section>
						</GridSeven>

						<GridFive>
							<Section class="rounded border border-neutral bg-neutral-100 p-6 text-neutral-900">
								<Heading as="h3" size="h4">
									Rules & Regulations
								</Heading>
								<ul class="flex list-disc flex-col gap-4 ps-6 text-sm">
									<li>
										Rounds may be played Monday – Thursday anytime or Friday – Sunday & holidays after 12:00 p.m. at
										participating Troon Golf daily-fee facilities (please note: At Kapalua, rounds can be play Monday –
										Sunday after 11AM. In the UAE rounds may be played Monday – Thursday anytime or Friday – Sunday &
										holidays after 12:00 p.m. In Bahrain and Saudi Arabia, rounds may be played Sunday – Thursday
										anytime or Friday – Saturday & holidays after 12:00 p.m.)
									</li>

									<li>
										When using Troon Rewards points for a discounted or complimentary round, tee times can be made up to
										3 days in advance, and use of Troon Rewards points must be mentioned at time of booking. VAT will be
										applied to points and points/cash redemptions in the United Arab Emirates
									</li>

									<li>
										If the Member has earned or redeemed Troon Rewards points in the last eighteen (18) months, Troon
										Rewards points do not expire.
									</li>

									<li>
										Troon Rewards redeemed points may be used based on availability and cannot be used in conjunction
										with groups (12 players or more), hotel/resort packages or with any other special offers
									</li>

									<li>
										The issuing of Troon Rewards points does not constitute a reservation. Members are responsible for
										making all reservations and communicating directly with a facility regarding Troon Rewards points
									</li>

									<li>Redemption options and locations are subject to change at any time</li>

									<li>
										Tee time reservations must be canceled no later than 48 hours prior to the day of play. Failure to
										cancel tee times without proper notification may result in being charged as a “No-Show” under the
										courses No-Show policy
									</li>

									<li>
										EXCLUSION DATES – Troon Rewards points and status discounts cannot be redeemed in AZ from Feb 5,
										2024 – Feb 11, 2024, during the Waste Management Phoenix Open, and cannot be redeemed at Kapalua
										during the Festive/Sentry TOC period of January 4, 2024- January 10, 2024
									</li>

									<li>
										Please see the Troon Rewards <TextLink href="/rewards/terms">TERMS AND CONDITIONS</TextLink>
									</li>

									<li>
										HOW TO REDEEM – Book your tee time with the course within the qualifying booking window listed above
										and mention you will be redeeming your Troon Rewards points
									</li>
								</ul>
							</Section>
						</GridFive>
					</Grid>
					<HorizontalRule />

					<Section>
						<Heading as="h2" id="options">
							Tee Time Redemption Options
						</Heading>

						<Tabs>
							<Section>
								<TabList>
									<Tab value="domestic">Domestic Locations</Tab>
									<Tab value="international">International Locations</Tab>
								</TabList>

								<RadioGroup name="display" class="flex-row gap-x-8" onSelect={setPointsType}>
									<Label class="sr-only">Rewards Redemption Option Type</Label>
									<Radio value="free" checked>
										<Label>Free with Points</Label>
									</Radio>
									<Radio value="half">
										<Label>Points + Half</Label>
									</Radio>
								</RadioGroup>

								<For each={Object.entries(data()?.chart ?? {})}>
									{([key, regions]) => (
										<TabPanel value={key}>
											<div class="divide-y divide-neutral border border-neutral">
												<For each={regions}>
													{(region) => (
														<Details class="w-full overflow-hidden">
															<Summary class="px-4 py-6 text-lg font-semibold group-open:mb-0 group-open:pb-4">
																{region.regionName}
															</Summary>
															<div class="overflow-x-auto">
																<table class="relative w-full">
																	<thead>
																		<tr>
																			<th
																				class="sticky left-0 border-e border-white bg-brand-700 px-3 py-2.5 text-start font-semibold uppercase text-white"
																				colSpan={2}
																			>
																				Course
																			</th>
																			<For each={[...Array(12).keys()]}>
																				{(m) => (
																					<th class="border-x border-white bg-brand-700 px-3 py-2.5 text-center font-semibold uppercase text-white">
																						{dayjs(new Date(Date.UTC(2024, (m + 1) % 12))).format('MMM')}
																					</th>
																				)}
																			</For>
																		</tr>
																	</thead>
																	<tbody>
																		<For each={region.entries}>
																			{(facility) => (
																				<For each={facility.courses}>
																					{(course, i) => (
																						<tr>
																							<Show when={i() === 0}>
																								<th
																									scope="row"
																									colSpan={facility.courses.length === 1 ? 2 : 1}
																									rowSpan={facility.courses.length}
																									class="sticky left-0 w-32 min-w-32 max-w-32 border border-s-0 border-neutral bg-neutral-100 px-6 py-4 text-start font-normal md:min-w-48 md:max-w-48"
																								>
																									<div class="flex flex-col gap-1">
																										<Link
																											href={`/course/${facility.slug}`}
																											class="text-base font-semibold after:absolute after:inset-0"
																										>
																											{facility.name}
																										</Link>
																										<span class="text-sm font-medium">{facility.location}</span>
																									</div>
																								</th>
																							</Show>
																							<Show when={facility.courses.length > 1}>
																								<th
																									scope="row"
																									class="sticky left-32 border border-s-0 border-neutral bg-neutral-100 px-6 py-4 text-start font-normal md:left-48"
																								>
																									{course.courseName}
																								</th>
																							</Show>
																							<For
																								each={[...Array(12).keys()].map((m) =>
																									course.months.find((month) => month.month === m + 1),
																								)}
																							>
																								{(month) => (
																									<td class="text-nowrap border border-neutral px-6 py-4 text-center">
																										<Show when={month} fallback="–">
																											{(month) => (
																												<Switch fallback="–">
																													<Match when={pointsType() === 'free' && month().freePoints}>
																														{points(month().freePoints as number)} pts
																													</Match>
																													<Match when={pointsType() === 'half'}>
																														{points(month().halfOffPoints as number)} pts +{' '}
																														{month().halfOffPrice?.displayValue}
																													</Match>
																												</Switch>
																											)}
																										</Show>
																									</td>
																								)}
																							</For>
																						</tr>
																					)}
																				</For>
																			)}
																		</For>
																	</tbody>
																</table>
															</div>
														</Details>
													)}
												</For>
											</div>
										</TabPanel>
									)}
								</For>
							</Section>
						</Tabs>
					</Section>
				</Page>
			</Container>
		</>
	);
}

export const route = { info: { hasHero: true }, preload: () => getData({}) } satisfies RouteDefinition;

const query = gql(`query rewardsRedemptionPage {
  chart: rewardRedemptionChart {
    domestic: domesticRegions {
      ...RewardRegion
    }
    international: internationalRegions {
      ...RewardRegion
    }
  }
}
fragment RewardRegion on FacilityRewardRedemptionRegion {
  regionName
  entries {
    name: facilityName
    slug: facilitySlug
    location
		courses { courseName, months { ...RedemptionMonth } }
  }
}
fragment RedemptionMonth on FacilityRewardRedemptionEntryMonth {
  month,
  halfOffPrice { displayValue }
  halfOffPoints
  freePoints
}`);

const getData = cachedQuery(query);

const points = Intl.NumberFormat('en-US').format;
